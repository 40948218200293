import { useRef, useState, useCallback, useEffect } from "react";
// import { InputGroup, FormControl, Input, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Unity, useUnityContext } from "react-unity-webgl";
import { Navbar } from '../../../component/Navbar';


export const GDWCSkyCityRushGame = () => {
    const canvasRef = useRef(null);
    const [isGameOver, setIsGameOver] = useState(false);
    const [userName, setUserName] = useState();
    const [score, setScore] = useState();

    const [devicePixelRatio, setDevicePixelRatio] = useState(window.devicePixelRatio);

    const { unityProvider, isLoaded, loadingProgression, requestFullscreen, addEventListener, removeEventListener } = useUnityContext({
        loaderUrl: "/sky-city-rush/Build/sky-city-rush.loader.js",
        dataUrl: "/sky-city-rush/Build/sky-city-rush.data",
        frameworkUrl: "/sky-city-rush/Build/sky-city-rush.framework.js",
        codeUrl: "/sky-city-rush/Build/sky-city-rush.wasm",
      });
    
    
    function fullScreen() {
        requestFullscreen(true);
    }

    function resizeCanvas() {
        if (canvasRef.current) {
            // canvasRef.current.focus();
            console.log("resize here");
        }
    }

    const handleGameOver = useCallback((userName, score) => {
        setIsGameOver(true);
        setUserName(userName);
        setScore(score);
    }, []);

    useEffect(
        function () {
          // A function which will update the device pixel ratio of the Unity
          // Application to match the device pixel ratio of the browser.
          const updateDevicePixelRatio = function () {
            setDevicePixelRatio(window.devicePixelRatio);
          };
          // A media matcher which watches for changes in the device pixel ratio.
          const mediaMatcher = window.matchMedia(
            `screen and (resolution: ${devicePixelRatio}dppx)`
          );
          // Adding an event listener to the media matcher which will update the
          // device pixel ratio of the Unity Application when the device pixel
          // ratio changes.
          mediaMatcher.addEventListener("change", updateDevicePixelRatio);
          return function () {
            // Removing the event listener when the component unmounts.
            mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
          };
        },
        [devicePixelRatio]
    );

    useEffect(() => {
        resizeCanvas();
        addEventListener("GameOver", handleGameOver);
        return () => {
          removeEventListener("GameOver", handleGameOver);
        };
    }, [addEventListener, removeEventListener, handleGameOver]);
    
    
    return <div>
        <Navbar />
        <div className="gameScreenContainer">
          <div className="progress-container">
            {!isLoaded ? 
              // <ProgressBar now={Math.round(loadingProgression * 100)} variant="info" striped animated />
              <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: `${Math.round(loadingProgression * 100)}%`}} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              : 
              null
            }
          </div>
          <Unity ref={canvasRef} unityProvider={unityProvider} devicePixelRatio={devicePixelRatio} className="landscape-webgame-canvas"/>
          {isGameOver === true && (
              <p>{`Game Over ${userName}! You've scored ${score} points.`}</p>
          )}
        </div>
        <button onClick={fullScreen}>Enter Fullscreen</button>
    </div>
}

// <button onClick={() => navigate(-1)}>Go back</button>