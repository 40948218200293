export const GamePage = () => {
    return <div>
        <div className="wrapper">
            <div className="product-img">
                <img className="product-thumbnail" src="https://play-lh.googleusercontent.com/THui6gT0WW0jY9yt1g5o7-x2gQoAq3O93B15xlsmRX2fogkIgyE3Rbysa55DC11tXYw=w480-h960-rw" alt="Addem Numbers" />
            </div>
            <div className="product-info">
                <div className="product-text">
                    <h1>Addem Numbers</h1>
                    <p>
                        Addem is the perfect game for you to get better at adding numbers.
                        You lose score on a wrong answer and you are rewarded with runway seconds on a correct answer.
                        Enjoy adding!
                    </p>
                </div>
            </div>
            <div className="app-link-button">
                <a target="_blank" rel="noreferrer" href='https://play.google.com/store/apps/details?id=com.poy.addem'>Download on Android</a>
            </div>
            <div className="app-link-button">
                <a target="_blank" rel="noreferrer" href='https://apps.apple.com/in/app/addem-numbers/id1668646839'>Download on iOS</a>
            </div>
        </div>
    </div>
}

// <button onClick={() => navigate(-1)}>Go back</button>