// import { useState, useCallback, useEffect } from "react";
// import { InputGroup, FormControl, Input, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Unity, useUnityContext } from "react-unity-webgl";


export const PosterPuzzleGame = () => {

    const { unityProvider, isLoaded, loadingProgression, takeScreenshot } = useUnityContext({
        loaderUrl: "/unizone-poster-puzzle/Build/unizone-poster-puzzle.loader.js",
        dataUrl: "/unizone-poster-puzzle/Build/unizone-poster-puzzle.data",
        frameworkUrl: "/unizone-poster-puzzle/Build/unizone-poster-puzzle.framework.js",
        codeUrl: "/unizone-poster-puzzle/Build/unizone-poster-puzzle.wasm",
        webGLContextAttributes: {
            preserveDrawingBuffer: true,
        }
    });

    function handleClickTakeScreenshot() {
        const dataUrl = takeScreenshot("/image/1.jpg", 0.5);
        window.open(dataUrl, "_blank");
    }
    
    
    return <div>
        {!isLoaded ? <p>Loading Application... {Math.round(loadingProgression * 100)}%</p> : null}
        <Unity unityProvider={unityProvider} className="unizone-game" />
        <button onClick={handleClickTakeScreenshot}>Take Screenshot</button>
    </div>
}

// <button onClick={() => navigate(-1)}>Go back</button>