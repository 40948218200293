import { useState, useCallback, useEffect } from "react";
import { InputGroup, FormControl, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export const AddemNumbersGame = () => {
    const INITIAL_TIME = 15;
    const DIFFICULTY_FACTOR = [1,2,3];
    const difficulty = 2;
    
    const [playerName, setPlayerName] = useState();
    const [scoreCount, setScoreCount] = useState(0);
    const [time, setTime] = useState(INITIAL_TIME);
    const [numbers, setNumbers] = useState({
        num1: null,
        num2: null
    });
    const [correctAnswer, setCorrectAnswer] = useState(null);
    const [answer, setAnswer] = useState();
    
    const [welcomeScreen, setWelcomeScreen] = useState(true);
    const [showWelcome, setShowWelcome] = useState(false);

    // const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    
    const [gameOverScreen, setGameOverScreen] = useState(true);
    const [showGameOver, setShowGameOver] = useState(false);
    


    var timer;

    useEffect(() => {
        setWelcomeScreen('xl-down');
        setShowWelcome(true);
    }, []);

    // useEffect(() => {
    //     generateNumbers();
    //     setTime(INITIAL_TIME);
    //     startTimer();
    //     // dispatch(newGame());
    //     return () => {
    //         clearInterval(timer);
    //     }
    // }, []);
    
    const startGame = () => {
        setScoreCount(0);
        generateNumbers();
        setTime(INITIAL_TIME);
        startTimer();
        if (showWelcome) {
            setWelcomeScreen(true);
            setShowWelcome(false);
        }
    }

    const updateTime = () => {
        setTime((time) => {
            if (time > 0) {
              return time - 1
            } else {
              handleGameOver();
            }
        });
    };

    const startTimer = () => {
        clearInterval(timer);
        timer = setInterval(() => {
          updateTime();
        }, 1000);
    };
    
    

    const generateNumbers = () => {
        let seed = scoreCount < 0 ? 0 : scoreCount;
        let min = seed + 1;
        let max = DIFFICULTY_FACTOR[difficulty] * (seed + DIFFICULTY_FACTOR[difficulty]);
    
        let num1 = parseInt(Math.random() * (max - min) + min);
        let num2 = parseInt(Math.random() * (max - min) + min);
        setCorrectAnswer(num1 + num2 + '');
        setNumbers({num1, num2});
    };

    const handleGameOver = () => {
        clearInterval(timer);
        openGameOverModal();
    };

    const renderTime = useCallback(() => {
        return <div>Time left: {time}</div>
    }, [time]);

    const increment = () => {
        let score = scoreCount + 1;
        setScoreCount(score);
    };
    
    const decrement = () => {
        let score = scoreCount - 1;
        setScoreCount(score);
    };
    
    const addTime = () => {
        let currTime = time + 3;
        setTime(currTime);
    };

    const checkAnswer = () => {
        if (answer && answer.length) {
            if (answer.trim() === correctAnswer) {
                increment();
                addTime();
            } else {
                decrement();
            }
            setAnswer(' ');
            generateNumbers();
        }
    };

    const handleChange = (event) => {
        setAnswer(event.target.value);
    };
    
    const handleNameChange = (event) => {
        setPlayerName(event.target.value);
    };

    const onKeyUp = (event) => {
        if (event.keyCode === 13) {
            console.log("onKeyUp ", event);
            checkAnswer();
            //   this.setState({ inputValue: event.target.value });
        }
    }

    // const handleShow = (breakpoint) => {
    //     setFullscreen(breakpoint);
    //     setShow(true);
    // }
    
    const openGameOverModal = () => {
        setGameOverScreen('xl-down');
        setShowGameOver(true);
    }
    

    return <div>
        <Modal show={showWelcome} fullscreen={welcomeScreen} onHide={() => setShowWelcome(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Addem Numbers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Welcome Human!
                <InputGroup>
                    <FormControl placeholder="Your Name..." onChange={handleNameChange} onKeyUp={(e) => onKeyUp(e)} value={playerName} />
                </InputGroup>
                <Button className="me-2 mb-2" onClick={() => {startGame()}}>
                    Start Game
                </Button>
            </Modal.Body>
            
        </Modal>
        <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>Modal body content</Modal.Body>
        </Modal>
        <Modal show={showGameOver} fullscreen={gameOverScreen} onHide={() => setShowGameOver(false)}>
            <Modal.Header>
                <Modal.Title>Game Over</Modal.Title>
            </Modal.Header>
            <Modal.Body>Your score: {scoreCount}</Modal.Body>
            <Button className="me-2 mb-2" onClick={() => {
                clearInterval(timer);
                setGameOverScreen(true);
                setShowGameOver(false);
                startGame('xl-down');
            }}>
                Play Again
            </Button>
        </Modal>
        <div className="gameScreenContainer">
            {playerName}
            {/* <div className="backButton">
                <button onPress={handleBackPress}>Main Menu</button>
            </div> */}
            
            <div className="infoContainer">
                <div className="timeDisplay">{renderTime()}</div>
                <div className="inlineContainer">
                <div className="scoreDisplay">Score: {scoreCount}</div> 
                <div className="scoreNumber"></div>
                </div>
            </div>
            
            <div className="gameView">
                <div className="headingText">Add these numbers...</div>
                <div className="bigText">
                    {numbers.num1} + {numbers.num2}
                </div>
                <InputGroup>
                    <FormControl placeholder="Answer..." onChange={handleChange} onKeyUp={(e) => onKeyUp(e)} value={answer} />
                </InputGroup>
            </div>
        </div>
    </div>
}

// <button onClick={() => navigate(-1)}>Go back</button>