import React, { useEffect, useState, useContext } from 'react';
import { auth } from '../../firebase';

import { Link, Outlet } from "react-router-dom"
import { onAuthStateChanged, signOut } from 'firebase/auth';
// import { Outlet } from "react-router-dom"
import { AuthContext } from '../../App';


export const AccountPage = () => {
    const [authUser, setAuthUser] = useState(null);
    const authContext = useContext(AuthContext);

    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
                authContext.authDispatch("LOGIN");
            } else {
                setAuthUser(null);
            }
        });
        return () => {
            listen();
        }
    }, [authContext]);

    const logOut = () => {
        signOut(auth).then(() => {
            console.log("Logged Out");
        }).catch((error) => console.log(error));
    }

    return (
        <div className="account-page-container">
            <div className="page-content">
                {authUser ? <>
                    <p className="">
                        {`Signed In as ${authUser.email}`}
                    </p>
                    <button onClick={logOut}>Log Out</button>
                    <hr/>
                    <div>
                        WebGames: <br/>
                        <Link to='/webgames/shoot-or-die'>Shoot or Die</Link><br/>
                        <Link to='/webgames/sky-city-rush'>Sky City Rush [prototype]</Link><br/>
                        {/* <Link to='/webgames/addem-numbers'>Addem Numbers</Link> */}
                    </div>
                </> : <p className="">Signed Out</p>}
                <Outlet />
            </div>
        </div>
    )
}