import { useRef, useState, useCallback, useEffect } from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar } from '../../../component/Navbar';
// import { GameApp } from '../../../assets/gameApps/macBuild.zip';


export const GDWCShootOrDieMultiplayerGame = () => {
    
    return <div>
        <Navbar />
        {/* <div className="gameScreenContainer">
          <div className="progress-container">
            {!isLoaded ? 
              // <ProgressBar now={Math.round(loadingProgression * 100)} variant="info" striped animated />
              <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: `${Math.round(loadingProgression * 100)}%`}} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              : 
              null
            }
          </div>
        </div> */}
        <a href="/gameApps/macBuild.zip" download="GameApp.zip" target='_blank'>
          <Button>Download Mac build</Button>
        </a>
        {/* <button onClick={downloadMacBuild}>Download Mac build</button> */}
    </div>
}

// <button onClick={() => navigate(-1)}>Go back</button>