import { NavLink, useLocation } from 'react-router-dom';

export const Navbar = () => {
    const location = useLocation();
    return (
        <nav className='primary-nav grid_pattern_background'>
            {location.pathname !== "/" ? <NavLink to='/'>Home</NavLink> : null}
            {location.pathname !== "/webgames" ? <NavLink to='/webgames'>Web Games</NavLink> : null}
            <NavLink target="_blank" to='https://discord.gg/xsKEuWEqz5'>Join Discord</NavLink>
            {/* <NavLink to='/blogs'>Blogs</NavLink> */}
        </nav>
    )
}