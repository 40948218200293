import { useSyncExternalStore, useState, useEffect } from 'react';

export default function useOnlineStatus() {
    function subscribe(callback) {
        window.addEventListener('online', callback);
        window.addEventListener('offline', callback);
        return () => {
            window.removeEventListener('online', callback);
            window.removeEventListener('offline', callback);
        };
    }
    return useSyncExternalStore(
        subscribe,
        () => navigator.onLine,
        () => true
    );
}