// import logo from './assets/logo.svg';
// import logo from './assets/logo.png';
import React, { useEffect, useReducer } from 'react';
import { usePointerPosition } from './customHooks/usePointerPosition';
// import { auth } from '../src/firebase';
import './App.scss';

import { Routes, Route } from 'react-router-dom';

import { Home } from './pages/Home';
// import { About } from './pages/About';
import { PointerDot } from './component/PointerDot';
import { Navbar } from './component/Navbar';
import { NetworkStatus } from './component/NetworkStatus';
// import { Footer } from './component/Footer';
import { AccountPage } from './pages/Account/AccountPage';
import { SignIn } from './pages/Account/SignIn';
// import { SignUp } from './pages/Account/SignUp';
import { ShootOrDie } from './pages/ShootOrDie/ShootOrDie';
import { AddemNumbers } from './pages/AddemNumbers/AddemNumbers';
import { GamePage } from './pages/AddemNumbers/GamePage';
import { PrivacyPolicy } from './pages/AddemNumbers/PrivacyPolicy';

import { DraknekGames } from './pages/DraknekGames/DraknekGames';
import { DraknekDonumGame } from './pages/DraknekGames/Donum/DraknekDonumGame';

import { GDWCWebGames } from './pages/GDWCWebGames/GDWCWebGames';
import { GDWCShootOrDieGame } from './pages/GDWCWebGames/ShootOrDie/GDWCShootOrDieGame';
import { GDWCSkyCityRushGame } from './pages/GDWCWebGames/SkyCityRush/GDWCSkyCityRushGame';
import { GDWCShootOrDieMultiplayerGame } from './pages/GDWCWebGames/ShootOrDieMultiplayer/GDWCShootOrDieMultiplayerGame';

import { WebGames } from './pages/WebGames/WebGames';
import { AddemNumbersGame } from './pages/WebGames/AddemNumbers/AddemNumbersGame';
import { ShootOrDieGame } from './pages/WebGames/ShootOrDie/ShootOrDieGame';
import { SkyCityRushGame } from './pages/WebGames/SkyCityRush/SkyCityRushGame';

import { Unizone } from './pages/Unizone/Unizone';
import { PosterPuzzleGame } from './pages/Unizone/PosterPuzzleGame';
// import { TermsOfService } from './pages/AddemNumbers/TermsOfService';
// import { NoMatch } from './pages/NoMatch';
// import { Blogs } from './pages/Blogs';
// import { FeaturedBlogs } from './pages/FeaturedBlogs';
// import { NewBlogs } from './pages/NewBlogs';

export const AuthContext = React.createContext();

const initialState = "LOGGED_OUT";
const reducer = (state, action) => {
  switch (action) {
    case 'LOGIN':
      return "LOGGED_IN";
    case "REGISTER":
      return "SIGNED_UP";
    case "LOGOUT":
      return "LOGGED_OUT";
    default:
      return state;
  }
}


function App() {
  
  const [ authState, dispatch ] = useReducer(reducer, initialState);
  const pos1 = usePointerPosition();

  return (
    <AuthContext.Provider value={{ authState: authState, authDispatch: dispatch }}>
      <PointerDot position={pos1} opacity={1} />
      <Navbar />
      {/* Auth State: {authState} */}
      <NetworkStatus />
      <Routes>
        <Route path='/' element={<Home />}></Route>
        {/* <Route path='/about' element={<About />}></Route> */}
        {/* <Route path='/privacy-policy' element={<PrivacyPolicy />}></Route> */}
        <Route path='/account' element={<AccountPage />}>
          <Route index element={<AccountPage />}></Route>
          <Route path='sign-in' element={<SignIn />}></Route>
          {/* <Route path='sign-up' element={<SignUp />}></Route> */}
        </Route>
        <Route path='/shoot-or-die-support' element={<ShootOrDie />}>
          <Route index element={<ShootOrDie />}></Route>
          {/* <Route path='privacy-policy' element={<PrivacyPolicy />}></Route> */}
          {/* <Route path='terms-of-service' element={<TermsOfService />}></Route> */}
        </Route>
        <Route path='/addem-numbers' element={<AddemNumbers />}>
          <Route index element={<GamePage />}></Route>
          <Route path='privacy-policy' element={<PrivacyPolicy />}></Route>
          {/* <Route path='terms-of-service' element={<TermsOfService />}></Route> */}
        </Route>
        <Route path='/webgames' element={<WebGames />}>
          <Route index element={<WebGames />}></Route>
          <Route path='addem-numbers' element={<AddemNumbersGame />}></Route>
          <Route path='shoot-or-die' element={<ShootOrDieGame />}></Route>
          <Route path='sky-city-rush' element={<SkyCityRushGame />}></Route>
          {/* <Route path='wordle' element={<WordleGame />}></Route> */}
        </Route>
        <Route path='/draknek' element={<DraknekGames />}>
          <Route index element={<DraknekGames />}></Route>
          <Route path='donum' element={<DraknekDonumGame />}></Route>
        </Route>
        <Route path='/gdwc' element={<GDWCWebGames />}>
          <Route index element={<GDWCWebGames />}></Route>
          {/* <Route path='addem-numbers' element={<AddemNumbersGame />}></Route> */}
          <Route path='shoot-or-die' element={<GDWCShootOrDieGame />}></Route>
          <Route path='sky-city-rush' element={<GDWCSkyCityRushGame />}></Route>
          <Route path='shoot-or-die-multiplayer' element={<GDWCShootOrDieMultiplayerGame />}></Route>
          {/* <Route path='wordle' element={<WordleGame />}></Route> */}
        </Route>
        <Route path='/unizone' element={<Unizone />}>
          <Route index element={<Unizone />}></Route>
          <Route path='poster-puzzle' element={<PosterPuzzleGame />}></Route>
        </Route>
        {/* <Route path='blogs' element={<Blogs />}>
          <Route index element={<FeaturedBlogs />}></Route>
          <Route path='featured' element={<FeaturedBlogs />}></Route>
          <Route path='new' element={<NewBlogs />}></Route>
        </Route>
        <Route path='*' element={<NoMatch />}></Route> */}
      </Routes>
      {/* <Footer /> */}
    </AuthContext.Provider>
  );
}
// Dynamic Routes: https://www.youtube.com/watch?v=P5xgsRIKJjo

export default App;
