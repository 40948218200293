// import { Link, Outlet } from "react-router-dom"
import { Outlet } from "react-router-dom"

export const AddemNumbers = () => {
    return (
        <div className="addem-numbers-page-container">
            <div className="page-content">
                {/* <nav>
                    <Link to='privacy-policy'>Privacy Policy</Link>
                    <Link to='terms-of-service'>Terms of Service</Link>
                </nav> */}
                <Outlet />
            </div>
        </div>
    )
}