import React, { useState, useContext, useEffect } from 'react';
import { auth, provider } from '../../firebase';
// import { signInAnonymously, signInWithEmailAndPassword } from 'firebase/auth';
// import { signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { AuthContext } from '../../App';

export const SignIn = () => {
    const [, setEmail] = useState();
    const [authUser, setAuthUser] = useState(null);
    // const [password, setPassword] = useState();
    const authContext = useContext(AuthContext);
    // const []

    useEffect(() => {
        if (localStorage.getItem("loen_user_email")) {
            authContext.authDispatch("LOGIN");
            setEmail(localStorage.getItem("loen_user_email"));
        }
        
    }, [authContext]);

    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
                authContext.authDispatch("LOGIN");
            } else {
                setAuthUser(null);
            }
        });
        return () => {
            listen();
        }
    }, [authContext]);


    // const signIn = (e) => {
    //     e.preventDefault();
    //     signInWithEmailAndPassword(auth, email, password)
    //         .then((userCredential) => {
    //             console.log(userCredential);
    //             authContext.authDispatch("LOGIN");
    //         }).catch((error) => {
    //             console.log("Error: ", error);
    //         })
    // };

    const signInWithGoogle = (e) => {
        e.preventDefault();
        signInWithPopup(auth, provider)
        .then((result) => {
            setEmail(result.user.email);
            localStorage.setItem("loen_user_email", result.user.email);
            authContext.authDispatch("LOGIN");
          // This gives you a Google Access Token. You can use it to access the Google API.
        //   const credential = GoogleAuthProvider.credentialFromResult(result);
        //   const token = credential.accessToken;
        //   // The signed-in user info.
        //   const user = result.user;
          // IdP data available using getAdditionalUserInfo(result)
          // ...
        }).catch((error) => {
            console.log(error);
          // Handle Errors here.
        //   const errorCode = error.code;
        //   const errorMessage = error.message;
          // The email of the user's account used.
        //   const email = error.customData.email;
          // The AuthCredential type that was used.
        //   const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
       
    };

    // const loginAsGuest = (e) => {
    //     e.preventDefault();
    //     signInAnonymously(auth)
    //         .then((res) => {
    //             console.log("Guest login: ", res.user.uid);
    //         })
    //         .catch((error) => {
    //             console.log(error.message);
    //         });
    // };
    return <div className="">
        {/* <form onSubmit={signIn}>
            <div>Log In</div>
            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}></input>
            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
            <button type="submit">Log In</button>
        </form> */}
        {!authUser ? <>
                <form onSubmit={signInWithGoogle}>
                    <button type="submit">Sign in with Google</button>
                </form>
            </> : null}
        {/* <form onSubmit={loginAsGuest}>
            <button type="submit">Login as Guest</button>
        </form> */}
    </div>
}

// <button onClick={() => navigate(-1)}>Go back</button>