export const PrivacyPolicy = () => {
    return <div className="text-left">
        <div className="product-img">
            <img className="product-thumbnail" 
                src="https://play-lh.googleusercontent.com/THui6gT0WW0jY9yt1g5o7-x2gQoAq3O93B15xlsmRX2fogkIgyE3Rbysa55DC11tXYw=w480-h960-rw" 
                alt="Addem Numbers" 
                />
        </div>
        <br />
        <br />
        <div className="game-title">
            Addem Numbers
        </div>
        <p className="simple-paragraph">
            Addem Numbers built by Loen Interactive is a free to play mobile game. 
            This page informs about the policies with the collection, use and disclosure of Personal Information of the users.
        </p>
        <p className="simple-paragraph">
            We believe in the privacy of the users. We value your trust in providing your information while using this application. Please go through the Privacy Policy:
        </p>
        <div className="sub-heading">
            Personal Information Collection and Use
        </div>
        <p className="simple-paragraph">
            The app collects basic information about the users like name and email id. This is for the purpose of keeping the score and help improve the skills of the player through score comparison with other players.
        </p>
        <div className="sub-heading">
            Device Information Collection and Use
        </div>
        <p className="simple-paragraph">
            The app does not collect any information from the device. However, the app stores the scores and user login information in the device for persistent experience.
        </p>
        <div className="sub-heading">
            Information Sharing
        </div>
        <p className="simple-paragraph">
            No personal information about the user or any non-personal information about the user or device information is shared with any thrid party.
        </p>
        <div className="sub-heading">
            Contact
        </div>
        <p className="simple-paragraph">
            For any questions related to the Privacy Policy, please mail us at support@loen.in
        </p>
        Hope you enjoy the game.
    </div>
}

// <button onClick={() => navigate(-1)}>Go back</button>