import { Link, Outlet, useLocation } from "react-router-dom";
// import { Outlet } from "react-router-dom";
import { AuthContext } from '../../App';
import React, { useContext, useEffect, useState } from 'react';
import { auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
// import { Navbar } from '../../component/Navbar';

export const GDWCWebGames = () => {
    const authContext = useContext(AuthContext);
    const [authUser, setAuthUser] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
                authContext.authDispatch("LOGIN");
            } else {
                setAuthUser(null);
            }
        });
        return () => {
            listen();
        }
    }, [authContext]);

    return (
        <div className="web-games-page-container">
            {/* <Navbar /> */}
            <div className="page-content">
                <nav>
                    {location.pathname === "/GDWCWebGames" || location.pathname === "/GDWCWebGames/" ? <>
                        <Link to='shoot-or-die'>Shoot or Die</Link><br/>
                        <Link to='sky-city-rush'>Sky City Rush [prototype]</Link>
                        <Link to='shoot-or-die-multiplayer'>Shoot or Die multiplayer</Link>
                        {/* <Link to='addem-numbers'>Addem Numbers</Link> */}
                    </> : null}
                    <Outlet />
                </nav>
            </div>
        </div>
    )
}