// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getAuth, GoogleAuthProvider } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDf2XFDmZMzekJ5I02tMM5jxdoCkGJ2O_s",
  authDomain: "loen-auth.firebaseapp.com",
  projectId: "loen-auth",
  storageBucket: "loen-auth.appspot.com",
  messagingSenderId: "53237278026",
  appId: "1:53237278026:web:ea1a8166231d7f31694db3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
export { auth, provider };