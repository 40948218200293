import { Link, Outlet } from "react-router-dom"
// import { Outlet } from "react-router-dom"

export const Unizone = () => {
    return (
        <div className="web-games-page-container">
            <div className="page-content">
                <nav>
                    <Link to='poster-puzzle'>Play Poster Puzzle</Link>
                </nav>
                <Outlet />
            </div>
        </div>
    )
}