export function PointerDot({ position, opacity }) {
    return (
      <div style={{
        position: 'absolute',
        borderRadius: '50%',
        opacity,
        transform: `translate(${position.x}px, ${position.y}px)`,
        pointerEvents: 'none',
        left: -70,
        top: -70,
        width: 140,
        height: 140,
      }} className="pointer-dot"></div>
    );
}