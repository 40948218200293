// import { Link, Outlet } from "react-router-dom"
// import { Outlet } from "react-router-dom"
import ImgOne from "../../assets/game_screenshots/1.png";
import ImgTwo from "../../assets/game_screenshots/2.png";
import ImgThree from "../../assets/game_screenshots/3.png";
import ImgFour from "../../assets/game_screenshots/4.png";
import ImgFive from "../../assets/game_screenshots/5.png";

export const ShootOrDie = () => {
    return (
        <div className="shoot-or-die-page-container">
            <div className="page-content">
                <h1>Shoot or Die</h1>
                {/* <nav>
                    <Link to='privacy-policy'>Privacy Policy</Link>
                    <Link to='terms-of-service'>Terms of Service</Link>
                </nav> */}
                Welcome to the support page for 'Shoot or Die', the ultimate action-packed game 
                that pits you against a relentless army of enemy droids. 
                We're here to provide you with the information you need to make the most of 
                your gaming experience and ensure your privacy and data security.
                <div className="gameScreenshotContainer">
                    <img alt="Shoot or Die" src={ImgOne} className="gameScreenshotLandscape"/>
                </div>
                <h4>Game Information</h4>
                <br/>
                <b>About the Game</b><br/>'Shoot or Die' is a thrilling 2D shooting game 
                where you take on the role of the last human survivor facing a robotic uprising. 
                Arm yourself with powerful weapons, such as the Fireball and Shield, and 
                utilize game-changing perks, to navigate dynamic battlefields 
                and outwit cunning enemy droids. 
                Can you rewrite history and save humanity one level at a time?
                <br/>
                <div className="gameScreenshotContainer">
                    <img alt="Shoot or Die" src={ImgTwo} className="gameScreenshotLandscape"/>
                </div>
                <b>Features</b><br/>
                <u>Intense Action:</u> Immerse yourself in an action-packed world of relentless battles.
                <br/><u>Diverse Enemies:</u> Battle against an array of unique enemy droids, each with its own abilities.
                <br/><u>Strategic Upgrades:</u> Power up your droid with game-changing perks and futuristic weaponry.
                <br/><u>Tactical Gameplay:</u> Engage in dynamic tactical combat on ever-expanding battlefields.
                <br/><u>Conquer the Green Menace:</u> Outsmart cunning enemies, including the Green Menace, and unlock new dimensions of strategy.
                <div className="gameScreenshotContainer">
                    <img alt="Shoot or Die" src={ImgThree} className="gameScreenshotLandscape"/>
                </div>
                <b>Privacy Policy</b><br/>
                Your privacy and data security are of the utmost importance to us. We are committed to providing a safe and secure gaming environment. 
                We do not collect or share any personal information without your consent. Here are the specifics:
                <ul>
                    <li>The app does not collect any personal information about the users.</li>
                    <li>The app does not collect any information from the device.</li>
                    <li>For any questions related to the Privacy Policy, please mail us at support@loen.in</li>
                </ul>

                <div className="gameScreenshotContainer">
                    <img alt="Shoot or Die" src={ImgFour} className="gameScreenshotLandscape"/>
                </div>
                <h4>Contact Us</h4>
                For any questions, concerns, or support requests related to 'Shoot or Die,' please feel free to contact us at [support@loen.in]. We are here to assist you and enhance your gaming experience.

                Thank you for choosing 'Shoot or Die'. We hope you enjoy the game, and we appreciate your trust in us to provide you with a fun and secure gaming adventure.
                <div className="gameScreenshotContainer">
                    <img alt="Shoot or Die" src={ImgFive} className="gameScreenshotLandscape"/>
                </div>
                Download 'Shoot or Die' from the <a href="https://apps.apple.com/in/app/shoot-or-die-mobile/id6469452088" target="_blank" rel="noreferrer">App Store</a> and Play Store.
                <br />
                <br />
                <br />
                🙂 Committed to follow the Play Families policy.
            </div>
        </div>
    )
}